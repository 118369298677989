import React from "react";

const SafetyStandardsPage = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Child Safety Standards and Compliance
        </h1>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Overview</h2>
          <p className="mt-2 text-lg text-gray-700">
            We take child safety seriously. Our app follows strict guidelines and
            complies with international and national laws to prevent child sexual
            abuse and exploitation (CSAE). This page outlines the child safety
            standards our app adheres to and the practices in place to protect children.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Our Standards</h2>
          <p className="mt-2 text-lg text-gray-700">
            We are committed to preventing child sexual abuse and exploitation on
            our platform. Our app follows these critical standards:
            <ul className="list-disc pl-6 mt-4 text-lg text-gray-700">
              <li>
                Regular monitoring of all user-generated content for any signs of CSAE.
              </li>
              <li>
                Immediate removal of content that violates child safety standards, with
                clear reporting mechanisms in place.
              </li>
              <li>
                Active moderation and reporting by a dedicated team of experts to ensure
                no inappropriate content remains.
              </li>
              <li>
                Full compliance with regional and national laws, including those relating
                to child sexual abuse and exploitation.
              </li>
            </ul>
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">User Reporting Mechanism</h2>
          <p className="mt-2 text-lg text-gray-700">
            We provide an in-app mechanism that allows users to report concerns related
            to child sexual abuse material (CSAM). If you come across any content that
            you believe is harmful or inappropriate, you can report it using the "Report"
            button available on all user-generated content.
          </p>
          <p className="mt-2 text-lg text-gray-700">
            Reports are reviewed by our moderation team, and we take immediate action
            to ensure that no harmful content remains on the platform.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Compliance with Laws</h2>
          <p className="mt-2 text-lg text-gray-700">
            Our app complies with all relevant child safety laws in the regions where
            it is used. This includes compliance with international standards and the
            specific requirements of local authorities in India and other countries.
            We report any discovered child sexual abuse material (CSAM) to the relevant
            authorities as required by law.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Contact for Child Safety Concerns</h2>
          <p className="mt-2 text-lg text-gray-700">
            If you have concerns regarding child safety or need to report CSAM, please
            contact us at:
            <a href="mailto:safety@dosso21.com" className="text-blue-600">
              support@dosso21.com
            </a>
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Reporting to Authorities</h2>
          <p className="mt-2 text-lg text-gray-700">
            In compliance with child safety laws, we report any confirmed CSAE to the
            relevant authorities. Our app ensures that we work in collaboration with
            local and international law enforcement to prevent CSAE.
          </p>
        </div>

       
      </div>
    </div>
  );
};

export default SafetyStandardsPage;
