// import React, { useState } from "react";
// const CricketLoginModal = ({ isOpen, onClose, onLogin }) => {
//   const [mobileNumber, setMobileNumber] = useState("");

//   const handleSendOtp = async () => {
//         if (validateForm()) return; // Stop if the form is invalid
//         setLoading(true);
//         try {
//             // User does not exist, send OTP
//             const otpResponse = await apiClient.post('/api/send-otp', { contactNumber: formData.mobile });
//             if (otpResponse.data.success) {
//               setOtpSent(true); // OTP sent successfully
//             } else {
//               alert('Failed to send OTP. Please try again.');
//           }
//         } catch (error) {
//           console.error('Error in checking user existence or sending OTP:', error);
//           alert(error.response?.data?.message || 'An error occurred. Please try again.');
//         } finally {
//           setLoading(false);
//         }
//       };
    
      
    
//       const handleVerifyOtp = async () => {
//         if (validateForm()) return;  // If the form is invalid, stop
    
//         setLoading(true);
//         try {
//           const response = await apiClient.post('/api/verify-otp', { contactNumber: formData.mobile, otp: formData.otp });
//           console.log('otp verified', response);
//           setOtpVerified(true);
//           handleLogin();
//         } catch (err) {
//           setOtpSent(false)
//           formData.otp = null;
//           alert(err.response?.data?.message || 'Error verifying OTP');
//           console.error('OTP verification error:', err);
//         }
//         setLoading(false);
//       };


//   const handleLogin = () => {
//     // Simulate OTP generation and validation
//     const otp = "1234"; // Simulating OTP (this should come from an actual OTP service)
//     onLogin(otp, mobileNumber); // Pass OTP and mobile number back to parent
//   };

//   if (!isOpen) return null;

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
//       <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
//         <h2 className="text-2xl font-bold text-center mb-4">Login</h2>

//         {/* Mobile Number Input */}
//         <div className="mb-4">
//           <input
//             type="text"
//             placeholder="Enter Mobile Number"
//             value={mobileNumber}
//             onChange={(e) => setMobileNumber(e.target.value)}
//             className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
//           />
//         </div>

//         {/* Buttons */}
//         <div className="flex justify-end space-x-2">
//           <button
//             onClick={handleLogin}
//             className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition"
//           >
//             Send OTP
//           </button>
//           <button
//             onClick={onClose}
//             className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 transition"
//           >
//             Close
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CricketLoginModal;











import apiClient from "axiosInstance";
import React, { useState } from "react";

const CricketLoginModal = ({ isOpen, onClose, onLogin }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSendOtp = async () => {
    if (!mobileNumber || mobileNumber.length !== 10) {
          alert("Please enter a valid 10-digit mobile number.");
          return;
        }
        setLoading(true);
        try {
          // Check if the user already exists
          const userExistsResponse = await apiClient.post('/api/cricket/check-user', { mobile: mobileNumber });
          if (userExistsResponse.data.status) {
            // User exists
            const otpResponse = await apiClient.post('/api/send-otp', { contactNumber: mobileNumber });
            if (otpResponse.data.success) {
              setOtpSent(true); // OTP sent successfully
            } else {
              alert('Failed to send OTP. Please try again.');
            }
          } else {
            // User does not exist, send OTP
            alert('you are not registered.');
          
          }
        } catch (error) {
          console.error('Error in checking user existence or sending OTP:', error);
          alert(error.response?.data?.message || 'An error occurred. Please try again.');
        } finally {
          setLoading(false);
        }
      };




  const handleVerifyOtp = async () => {
    if (!otp || otp.length !== 5) {
      alert("Please enter a valid 5 digit OTP.");
      return;
    }

    setLoading(true);
    try {
      const response = await apiClient.post('/api/verify-otp', { contactNumber: mobileNumber, otp: otp });
      console.log('otp verified', response);
      // alert("OTP verified successfully!");
      
      onLogin(mobileNumber); // Notify parent of successful login
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Invalid OTP. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white rounded-lg shadow-lg p-6 w-11/12 max-w-md">
        <h2 className="text-2xl font-bold text-center mb-4">Login</h2>

        {/* Mobile Number Input (Visible when OTP is not sent) */}
        {!otpSent && (
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter Mobile Number"
              value={mobileNumber}
              onChange={(e) => setMobileNumber(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        )}

        {/* OTP Input (Visible when OTP is sent) */}
        {otpSent && (
          <div className="mb-4">
            <input
              type="text"
              placeholder="Enter OTP"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        )}

        {/* Buttons */}
        <div className="flex justify-end space-x-2">
          {!otpSent && (
            <button
              onClick={handleSendOtp}
              className={`px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Sending..." : "Send OTP"}
            </button>
          )}

          {otpSent && (
            <button
              onClick={handleVerifyOtp}
              className={`px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition ${
                loading ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={loading}
            >
              {loading ? "Verifying..." : "Verify"}
            </button>
          )}

          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-300 text-gray-700 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 transition"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CricketLoginModal;

