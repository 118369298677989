
// import apiClient from 'axiosInstance';
// import React, { useEffect, useState } from 'react';

// const CricketDashboardModal = ({ isOpen, onClose }) => {
//   const [userDetails, setUserDetails] = useState({
//     name: '',
//     mobile: '',
//     walletAmount: 0,
//   });
//   const [withdrawAmount, setWithdrawAmount] = useState(''); // Store the input amount
//   const [withdrawStatus, setWithdrawStatus] = useState(''); // For managing withdraw status

//   const fetchUserDetails = async () => {
//     try {
//       const response = await apiClient.get('/api/cricket/user/details');
//       console.log('User details fetched:', response.data.data);
      
//       setUserDetails({
//         name: response.data.data.name, // User's name
//         mobile: response.data.data.mobile, // User's mobile number
//         walletAmount: response.data.wallet.balance, // Wallet balance
//       });
//     } catch (error) {
//       console.error('Error fetching user details:', error);
//     }
//   };

//   const withdrawRequest = async () => {
//     // Validate amount input
//     const amountToWithdraw = parseFloat(withdrawAmount);
//     if (isNaN(amountToWithdraw) || amountToWithdraw <= 0) {
//       setWithdrawStatus('Please enter a valid amount.');
//       return;
//     }

//     if (amountToWithdraw > userDetails.walletAmount) {
//       setWithdrawStatus('Insufficient balance.');
//       return;
//     }

//     try {
//       const response = await apiClient.post('/api/cricket/user/withdraw', {
//         userId: userDetails.mobile, // Use user mobile or ID
//         amount: amountToWithdraw,
//       });

//       if (response.status === 200) {
//         setWithdrawStatus('your withdraw request has been taken our team will contact you within  48 hours');
//         alert('your withdraw request has been taken our team will contact you within  48 hours')
//         // Fetch the latest user details after withdrawal
//         fetchUserDetails(); // Update wallet balance
//       } else {
//         setWithdrawStatus('Failed to submit withdraw request');
//       }
//     } catch (error) {
//       console.error('Error submitting withdraw request:', error);
//       setWithdrawStatus('Error submitting withdraw request');
//     }
//   };

//   useEffect(() => {
//     if (isOpen) {
//       const token = localStorage.getItem('tokenfordossoclientuser');
//       if (token) {
//         fetchUserDetails(token);
//       }
//     }
//   }, [isOpen]); // Re-run the effect when the modal opens

//   if (!isOpen) return null; // Don't render the modal if it's closed

//   return (
//     <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
//       <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
//         <h2 className="text-2xl font-semibold text-center mb-4">Dashboard</h2>
        
//         {/* User Details */}
//         <div className="mb-4">
//           <p className="text-lg font-medium">Name: <span className="font-normal">{userDetails.name}</span></p>
//           <p className="text-lg font-medium">Mobile: <span className="font-normal">{userDetails.mobile}</span></p>
//           <p className="text-lg font-medium">Wallet Amount: <span className="font-normal">₹{userDetails.walletAmount}</span></p>
//         </div>

//         {/* Withdraw Amount Input */}
//         <div className="mb-4">
//           <label className="block text-lg font-medium" htmlFor="withdrawAmount">Withdraw Amount</label>
//           <input
//             id="withdrawAmount"
//             type="number"
//             min="1"
//             value={withdrawAmount}
//             onChange={(e) => setWithdrawAmount(e.target.value)}
//             className="w-full px-3 py-2 border border-gray-300 rounded-lg"
//             placeholder="Enter amount to withdraw"
//           />
//         </div>

//         {/* Withdraw Status */}
//         {withdrawStatus && (
//           <div className="mb-4 text-center text-green-600 font-medium">
//             {withdrawStatus}
//           </div>
//         )}

//         {/* Buttons */}
//         <div className="flex justify-center mt-4 space-x-4">
//           <button
//             onClick={onClose}
//             className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
//           >
//             Close
//           </button>
//           <button
//             onClick={withdrawRequest}
//             className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
//           >
//             Withdraw Request
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CricketDashboardModal;






import apiClient from 'axiosInstance';
import React, { useEffect, useState } from 'react';

const CricketDashboardModal = ({ isOpen, onClose }) => {
  const [userDetails, setUserDetails] = useState({
    name: '',
    mobile: '',
    walletAmount: 0,
  });
  const [withdrawAmount, setWithdrawAmount] = useState(''); // Store the input amount
  const [withdrawStatus, setWithdrawStatus] = useState(''); // For managing withdraw status
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false); // Manage confirmation modal visibility

  const fetchUserDetails = async () => {
    try {
      const response = await apiClient.get('/api/cricket/user/details');
      console.log('User details fetched:', response.data.data);
      
      setUserDetails({
        name: response.data.data.name, // User's name
        mobile: response.data.data.mobile, // User's mobile number
        walletAmount: response.data.wallet.balance, // Wallet balance
      });
    } catch (error) {
      console.error('Error fetching user details:', error);
    }
  };

  const withdrawRequest = async () => {
    // Validate amount input
    const amountToWithdraw = parseFloat(withdrawAmount);
    if (isNaN(amountToWithdraw) || amountToWithdraw <= 0) {
      setWithdrawStatus('Please enter a valid amount.');
      return;
    }

    
    if (amountToWithdraw > userDetails.walletAmount) {
      setWithdrawStatus('Insufficient balance.');
      return;
    }

    // Show confirmation modal
    setIsConfirmationOpen(true);
  };

  const confirmWithdraw = async () => {
    // Proceed with withdrawal after confirmation
    const amountToWithdraw = parseFloat(withdrawAmount);
    
    try {
      const response = await apiClient.post('/api/cricket/user/withdraw', {
        userId: userDetails.mobile, // Use user mobile or ID
        amount: amountToWithdraw,
      });

      if (response.status === 200) {
        setWithdrawStatus('Your withdraw request has been taken. Our team will contact you within 48 hours.');
        alert('Your withdraw request has been taken. Our team will contact you within 48 hours.');
        // Fetch the latest user details after withdrawal
        fetchUserDetails(); // Update wallet balance
      } else {
        setWithdrawStatus('Failed to submit withdraw request');
      }
    } catch (error) {
      console.error('Error submitting withdraw request:', error);
      setWithdrawStatus('Error submitting withdraw request');
    }

    // Close confirmation modal
    setIsConfirmationOpen(false);
  };

  const cancelWithdraw = () => {
    // Close confirmation modal without withdrawing
    setIsConfirmationOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      const token = localStorage.getItem('tokenfordossoclientuser');
      if (token) {
        fetchUserDetails(token);
      }
    }
  }, [isOpen]); // Re-run the effect when the modal opens

  if (!isOpen) return null; // Don't render the modal if it's closed

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
        <h2 className="text-2xl font-semibold text-center mb-4">Dashboard</h2>
        
        {/* User Details */}
        <div className="mb-4">
          <p className="text-lg font-medium">Name: <span className="font-normal">{userDetails.name}</span></p>
          <p className="text-lg font-medium">Mobile: <span className="font-normal">{userDetails.mobile}</span></p>
          <p className="text-lg font-medium">Wallet Amount: <span className="font-normal">₹{userDetails.walletAmount}</span></p>
        </div>

        {/* Withdraw Amount Input */}
        <div className="mb-4">
          <label className="block text-lg font-medium" htmlFor="withdrawAmount">Withdraw Amount</label>
          <input
            id="withdrawAmount"
            type="number"
            min="1"
            value={withdrawAmount}
            onChange={(e) => setWithdrawAmount(e.target.value)}
            className="w-full px-3 py-2 border border-gray-300 rounded-lg"
            placeholder="Enter amount to withdraw"
          />
        </div>

        {/* Withdraw Status */}
        {withdrawStatus && (
          <div className="mb-4 text-center text-green-600 font-medium">
            {withdrawStatus}
          </div>
        )}

        {/* Buttons */}
        <div className="flex justify-center mt-4 space-x-4">
          <button
            onClick={onClose}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Close
          </button>
          <button
            onClick={withdrawRequest}
            className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Withdraw Request
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      {isConfirmationOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white rounded-lg shadow-xl p-6 max-w-sm w-full">
            <h3 className="text-xl font-semibold text-center mb-4">Confirm Withdrawal</h3>
            <p className="text-lg mb-4">Are you sure you want to withdraw ₹{withdrawAmount}?</p>
            <div className="flex justify-center space-x-4">
              <button
                onClick={cancelWithdraw}
                className="bg-gray-500 text-white py-2 px-4 rounded-lg hover:bg-gray-600"
              >
                No
              </button>
              <button
                onClick={confirmWithdraw}
                className="bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600"
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CricketDashboardModal;
