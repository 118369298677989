

import { FaBars } from "react-icons/fa"; // React Icons
import RegistrationModal from "components/CricketRegister";
import PaymentModal from "../components/PaymentModal";
import React, { useState, useEffect, useRef } from "react";
import CricketLoginModal from "components/CricketLoginModal";
import apiClient from "axiosInstance";
import DashboardModal from "components/CricketDashboardModa";

const VideoBackgroundComponent = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [showSupport, setShowSupport] = useState(false); // State to toggle support number visibility
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status
  const [showBar, setShowBar] = useState(false); // State to toggle the visibility of the bar
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false); // For Dashboard Modal

  const barRef = useRef(null); // Reference for the collapsible bar
  const barButtonRef = useRef(null); // Reference for the toggle button to prevent closing when clicking the button


  // Handle clicks outside of the bar
  // Handle clicks outside of the bar
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the bar and not the button
      if (
        barRef.current && !barRef.current.contains(event.target) && 
        barButtonRef.current && !barButtonRef.current.contains(event.target)
      ) {
        setShowBar(false); // Hide the bar if clicked outside
      }
    };

    // Add the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  // Check if the user is already logged in when the component mounts
  useEffect(() => {
    const token = localStorage.getItem('tokenfordossoclientuser');
    if (token) {
      setIsLoggedIn(true);
      // fetchUserDetails(token);
    }
  }, []);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const toggleSupport = () => {
    setShowSupport((prev) => !prev); // Toggle the support number visibility
  };

  const handleLoginLogout = () => {
    if (isLoggedIn) {
      // Log out the user
      localStorage.removeItem('tokenfordossoclientuser');
      setIsLoggedIn(false);
      setUserDetails(null); // Reset user details
    } else {
      setLoginModalOpen(true); // Open login modal if not logged in
    }
  };

  const openDashboardModal = () => {
    setDashboardModalOpen(true); // Open dashboard modal
  };

  const closeDashboardModal = () => {
    setDashboardModalOpen(false); // Close dashboard modal
  };


  const toggleBar = () => {
    setShowBar(!showBar); // Toggle bar visibility
  };

  const handleLogin = async (mobileNumber) => {
    try {
      // Request login (via OTP verification)
      const result = await apiClient.post('/api/cricket/user/login', { mobile: mobileNumber });
      console.log('result:', result);

      if (result.data.token) {
        const token = result.data.token;
        localStorage.setItem('tokenfordossoclientuser', token); // Store the token in localStorage
        setDashboardModalOpen(true);
        setIsLoggedIn(true); // Update logged-in state
        setLoginModalOpen(false)
        // fetchUserDetails(token); // Fetch user details after login
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };



  return (
    <div className="relative h-screen w-full overflow-hidden">
      {/* Video Background */}
      <video className="absolute top-0 left-0 w-full h-full object-cover" autoPlay loop muted>
        <source src="stadium.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Content on top of the video */}
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
        <img
          src="https://dosso21.com/logo.png"
          alt="Logo"
          className="mb-6 w-24 md:w-32 animate-bounce duration-1000"
        />
        <h1 className="text-4xl md:text-6xl font-bold mb-4">Dosso21 Premier League</h1>
      </div>

      {/* Registration Modal */}
      <div className="absolute top-6 right-20">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          <RegistrationModal />
        </div>
      </div>

      {/* Navigation Bar */}
      <div className="absolute top-4 right-4">
        <button
          ref={barButtonRef}
          onClick={toggleBar}
          className="text-white flex items-center space-x-2 hover:text-blue-800 hover:scale-105 transition-transform"
        >
          <FaBars />
        </button>
      </div>

      {/* Collapsible Bar */}
      {showBar && (
        <div className="absolute top-8 right-4" ref={barRef}>
          <div className="mt-2 bg-black bg-opacity-70 p-4 rounded-lg flex flex-col space-y-2 right-4">
            {/* Pay Now */}
            <button
              onClick={openModal}
              className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
            >
              Pay Now
            </button>

            {/* Support */}
            {!showSupport && (
              <button
                onClick={toggleSupport}
                className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
              >
                Support
              </button>
            )}

            {/* Show Support Number */}
            {showSupport && 
            <div 
            className="text-white text-lg font-semibold text-left"
            >
               <button
                className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
              >9289096788</button>
             
            </div>}

            {/* Dashboard (only when logged in) */}
            {isLoggedIn && (
              <button
                onClick={() => {openDashboardModal()}}
                className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
              >
                Dashboard
              </button>
            )}

            {/* Login/Logout */}
            <button
              onClick={handleLoginLogout}
              className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
            >
              {isLoggedIn ? "Logout" : "Login"}
            </button>
          </div>
        </div>
      )}

      {/* Conditional Rendering for Register Button */}
      {/* {showBar && ( */}
      {/* )} */}

      {/* Registration Modal */}
      <PaymentModal isOpen={modalOpen} closeModal={closeModal} />
      <CricketLoginModal
        isOpen={loginModalOpen}
        onClose={() => setLoginModalOpen(false)}
        onLogin={handleLogin}
      />

       {/* Dashboard Modal */}
       <DashboardModal
        isOpen={dashboardModalOpen}
        onClose={closeDashboardModal}
        userDetails={userDetails}
      />

    </div>
  );
};

export default VideoBackgroundComponent;







// import { FaBars } from "react-icons/fa"; // React Icons
// import RegistrationModal from "components/CricketRegister";
// import PaymentModal from "../components/PaymentModal";
// import React, { useState, useEffect, useRef } from "react";
// import CricketLoginModal from "components/CricketLoginModal";
// import apiClient from "axiosInstance";
// import DashboardModal from "components/CricketDashboardModa";

// const VideoBackgroundComponent = () => {
//   const [modalOpen, setModalOpen] = useState(false);
//   const [showSupport, setShowSupport] = useState(false); // State to toggle support number visibility
//   const [isLoggedIn, setIsLoggedIn] = useState(false); // State to manage login status
//   const [showBar, setShowBar] = useState(false); // State to toggle the visibility of the bar
//   const [loginModalOpen, setLoginModalOpen] = useState(false);
//   const [userDetails, setUserDetails] = useState(null);
//   const [dashboardModalOpen, setDashboardModalOpen] = useState(false); // For Dashboard Modal

//   const [loading, setLoading] = useState(true); // Loading state for video
//   const barRef = useRef(null); // Reference for the collapsible bar
//   const barButtonRef = useRef(null); // Reference for the toggle button to prevent closing when clicking the button

//   // Handle clicks outside of the bar
//   useEffect(() => {
//     const handleClickOutside = (event) => {
//       // Check if click is outside the bar and not the button
//       if (
//         barRef.current && !barRef.current.contains(event.target) && 
//         barButtonRef.current && !barButtonRef.current.contains(event.target)
//       ) {
//         setShowBar(false); // Hide the bar if clicked outside
//       }
//     };

//     // Add the event listener
//     document.addEventListener("mousedown", handleClickOutside);

//     // Cleanup the event listener
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, []);

//   // Check if the user is already logged in when the component mounts
//   useEffect(() => {
//     const token = localStorage.getItem('tokenfordossoclientuser');
//     if (token) {
//       setIsLoggedIn(true);
//       // fetchUserDetails(token);
//     }
//   }, []);

//   // Function to handle video load
//   const handleVideoLoaded = () => {
//     setLoading(false); // Hide the loader after the video is loaded
//   };

//   const openModal = () => {
//     setModalOpen(true);
//   };

//   const closeModal = () => {
//     setModalOpen(false);
//   };

//   const toggleSupport = () => {
//     setShowSupport((prev) => !prev); // Toggle the support number visibility
//   };

//   const handleLoginLogout = () => {
//     if (isLoggedIn) {
//       // Log out the user
//       localStorage.removeItem('tokenfordossoclientuser');
//       setIsLoggedIn(false);
//       setUserDetails(null); // Reset user details
//     } else {
//       setLoginModalOpen(true); // Open login modal if not logged in
//     }
//   };

//   const openDashboardModal = () => {
//     setDashboardModalOpen(true); // Open dashboard modal
//   };

//   const closeDashboardModal = () => {
//     setDashboardModalOpen(false); // Close dashboard modal
//   };

//   const toggleBar = () => {
//     setShowBar(!showBar); // Toggle bar visibility
//   };

//   const handleLogin = async (mobileNumber) => {
//     try {
//       // Request login (via OTP verification)
//       const result = await apiClient.post('/api/cricket/user/login', { mobile: mobileNumber });
//       console.log('result:', result);

//       if (result.data.token) {
//         const token = result.data.token;
//         localStorage.setItem('tokenfordossoclientuser', token); // Store the token in localStorage
//         setDashboardModalOpen(true);
//         setIsLoggedIn(true); // Update logged-in state
//         setLoginModalOpen(false)
//         // fetchUserDetails(token); // Fetch user details after login
//       }
//     } catch (error) {
//       console.error('Login error:', error);
//     }
//   };

//   return (
//     <div className="relative h-screen w-full overflow-hidden">
//       {/* Video Background */}
//       <video
//         className="absolute top-0 left-0 w-full h-full object-cover"
//         autoPlay
//         loop
//         muted
//         onLoadedData={handleVideoLoaded} // Triggered when enough data is loaded
//       >
//         <source src="stadium.mp4" type="video/mp4" />
//         Your browser does not support the video tag.
//       </video>

//       {/* Loader - Show until the video is ready */}
//       {loading && (
//         <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
//           <div className="w-16 h-16 border-4 border-t-blue-500 border-gray-200 rounded-full animate-spin"></div>
//         </div>
//       )}

//       {/* Content on top of the video */}
//       <div className="absolute inset-0 flex flex-col justify-center items-center text-white">
//         <img
//           src="https://dosso21.com/logo.png"
//           alt="Logo"
//           className="mb-6 w-24 md:w-32 animate-bounce duration-1000"
//         />
//         <h1 className="text-4xl md:text-6xl font-bold mb-4">Dosso21 Premier League</h1>
//       </div>

//       {/* Registration Modal */}
//       <div className="absolute top-6 right-20">
//         <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
//           <RegistrationModal />
//         </div>
//       </div>

//       {/* Navigation Bar */}
//       <div className="absolute top-4 right-4">
//         <button
//           ref={barButtonRef}
//           onClick={toggleBar}
//           className="text-white flex items-center space-x-2 hover:text-blue-800 hover:scale-105 transition-transform"
//         >
//           <FaBars />
//         </button>
//       </div>

//       {/* Collapsible Bar */}
//       {showBar && (
//         <div className="absolute top-8 right-4" ref={barRef}>
//           <div className="mt-2 bg-black bg-opacity-70 p-4 rounded-lg flex flex-col space-y-2 right-4">
//             {/* Pay Now */}
//             <button
//               onClick={openModal}
//               className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
//             >
//               Pay Now
//             </button>

//             {/* Support */}
//             {!showSupport && (
//               <button
//                 onClick={toggleSupport}
//                 className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
//               >
//                 Support
//               </button>
//             )}

//             {/* Show Support Number */}
//             {showSupport && 
//             <div 
//             className="text-white text-lg font-semibold text-left"
//             >
//                <button
//                 className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
//               > 8882199108</button>
//               </div>
//             }

//             {/* Dashboard (only when logged in) */}
//             {isLoggedIn && (
//               <button
//                 onClick={() => {openDashboardModal()}}
//                 className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
//               >
//                 Dashboard
//               </button>
//             )}

//             {/* Login/Logout */}
//             <button
//               onClick={handleLoginLogout}
//               className="text-white px-6 py-2 hover:text-blue-800 hover:scale-105 transition-transform text-left"
//             >
//               {isLoggedIn ? "Logout" : "Login"}
//             </button>
//           </div>
//         </div>
//       )}

//       {/* Registration Modal */}
//       <PaymentModal isOpen={modalOpen} closeModal={closeModal} />
//       <CricketLoginModal
//         isOpen={loginModalOpen}
//         onClose={() => setLoginModalOpen(false)}
//         onLogin={handleLogin}
//       />

//        {/* Dashboard Modal */}
//        <DashboardModal
//         isOpen={dashboardModalOpen}
//         onClose={closeDashboardModal}
//         userDetails={userDetails}
//       />
//     </div>
//   );
// };

// export default VideoBackgroundComponent;
