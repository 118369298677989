import React, { useState } from 'react';
// import apiClient from '../axios/axiosInstance.js'; // Import your Axios instance
import { useNavigate,Link } from 'react-router-dom';
import { FiEye, FiEyeOff } from "react-icons/fi";

import apiClient from 'axiosInstance';

const Login = () => {
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);


  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await apiClient.post('/api/login', {
        mobile,
        password,
      });

      // Store the token in local storage
      localStorage.setItem('tokenfordossoclientuser', response.data.token);

      navigate('/dashboard');

      // Optionally, redirect or update UI on successful login
      console.log("Logged in successfully", response.data);
    } catch (err) {
      // Check if error response exists and set the message from backend if available
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);  // Set the backend error message
      } else {
        setError('Login failed. Please check your credentials.');  // Fallback message
      }
      console.error('Login error:', err);
    }    
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-100">
      <form 
        onSubmit={handleSubmit} 
        className="bg-white p-8 rounded shadow-md w-96"
      >
        <h2 className="text-2xl font-bold mb-6 text-center">Login</h2>
        
        {error && <div className="text-red-500 mb-4">{error}</div>}
        
        <div className="mb-4">
          <label htmlFor="mobile" className="block text-gray-700">Mobile Number</label>
          <input 
            type="tel" 
            id="mobile" 
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
            className="mt-1 block w-full border border-gray-300 p-2 rounded focus:outline-none focus:ring-2 focus:ring-blue-500" 
            placeholder="Enter your mobile number" 
            required 
          />
        </div>

        <div className="mb-4">
      <label htmlFor="password" className="block text-gray-700">Password</label>
      <div className="relative">
        <input
          type={showPassword ? "text" : "password"}
          id="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          required
        />
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-500"
        >
          {showPassword ? (
            <FiEyeOff className="h-5 w-5" />
          ) : (
            <FiEye className="h-5 w-5" />
          )}
        </button>
      </div>
    </div>

        <button 
          type="submit" 
          className="w-full bg-theme-blue text-white p-2 rounded hover:bg-theme-blue transition"
        >
          Login
        </button>

        <div className="mt-4 text-center">
          <Link to="/forgot-password" className="text-blue-500 hover:underline">
            Forgot Password?
          </Link>
        </div>
        
      </form>
    </div>
  );
};

export default Login;
