// import React, { useState } from 'react';
// import Modal from 'react-modal';
// import { IoClose } from 'react-icons/io5';
// import apiClient from 'axiosInstance';

// const PaymentModal = ({ isOpen, closeModal }) => {
//   const [mobile, setMobile] = useState('');
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState('');
//   const [price] = useState(1280); // Assuming the price is fixed

//   const handlePayment = async () => {
//     const data = {
//       mobile: mobile,
//       MUID: "MUID" + Date.now(),
//     };

//     try {
//       setLoading(true);
//       // Call the API for payment initiation
//       const response = await apiClient.post(`/api/phone/pay/cricket/user`, data);
//       window.location.href = response.data.redirectUrl; // Redirect to payment gateway
//     }  catch (err) {
//         // Set the error message from backend response or fallback message
//         if (err.response && err.response.data && err.response.data.message) {
//         //   setErrorMessage(err.response.data.message);
//           alert(err.response.data.message)          
//         } else {
//         //   setError('Login failed. Please check your credentials.');
//         }
//         console.error('Login error:', err);
//       } finally {
//       setLoading(false);
//     }
//   };

//   return (
//     <Modal
//       isOpen={isOpen}
//       onRequestClose={closeModal}
//       contentLabel="Payment Modal"
//       className="max-w-md mx-auto my-20 bg-white p-6 rounded-lg shadow-lg overflow-auto max-h-screen"
//       overlayClassName="fixed inset-0 bg-black bg-opacity-50"
//     >
//       {/* Modal Header with Close Icon */}
//       <div className="flex items-center justify-between mb-4">
//         <h2 className="text-2xl font-semibold">Enter Mobile Number</h2>
//         <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 focus:outline-none">
//           <IoClose size={24} />
//         </button>
//       </div>

//       {/* Mobile Number Input */}
//       <div className="mb-4">
//         <label className="block mb-2">Mobile Number</label>
//         <input
//           type="text"
//           value={mobile}
//           onChange={(e) => setMobile(e.target.value)}
//           className="w-full p-2 border border-gray-300 rounded-md"
//           placeholder="Enter your mobile number"
//           required
//         />
//       </div>

//       {/* Payment Button */}
//       <div className="mt-4">
//         {loading ? (
//           <button className="bg-gray-600 text-white px-6 py-3 rounded-md" disabled>
//             Loading...
//           </button>
//         ) : (
//           <button
//             onClick={handlePayment}
//             className="bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700"
//           >
//             Pay {price} INR
//           </button>
//         )}
//       </div>

//       {/* Error Message */}
//       {error && <p className="text-red-500 mt-2">{error}</p>}
//     </Modal>
//   );
// };

// export default PaymentModal;



import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5';
import apiClient from 'axiosInstance';

const PaymentModal = ({ isOpen, closeModal, initialMobile }) => {
  const [mobile, setMobile] = useState(initialMobile || ''); // Set initial mobile if passed as prop
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [price] = useState(1280); // Assuming the price is fixed

  // Update mobile if initialMobile prop changes
  useEffect(() => {
    if (initialMobile) {
      setMobile(initialMobile);
    }
  }, [initialMobile]);

  const handlePayment = async () => {
    if (!mobile) {
      setError('Please enter a valid mobile number');
      return;
    }

    const data = {
      mobile: mobile,
      MUID: "MUID" + Date.now(),
      transactionId: 'T' + Date.now(),
    };

    try {
      setLoading(true);
      // Call the API for payment initiation
      const response = await apiClient.post(`/api/phone/pay/cricket/user`, data);
      window.location.href = response.data.redirectUrl; // Redirect to payment gateway
    } catch (err) {
      // Set the error message from backend response or fallback message
      if (err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError('An error occurred. Please try again later.');
      }
      console.error('Payment error:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Payment Modal"
      className="max-w-md mx-auto my-20 bg-white p-6 rounded-lg shadow-lg overflow-auto max-h-screen"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      {/* Modal Header with Close Icon */}
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-semibold">Enter Mobile Number</h2>
        <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 focus:outline-none">
          <IoClose size={24} />
        </button>
      </div>

      {/* Mobile Number Input */}
      <div className="mb-4">
        <label className="block mb-2">Mobile Number</label>
        <input
          type="text"
          value={mobile}
          onChange={(e) => setMobile(e.target.value)}
          className="w-full p-2 border border-gray-300 rounded-md"
          placeholder="Enter your mobile number"
          required
          disabled={!!initialMobile} 
        />
      </div>

      {/* Error Message */}
      {error && <p className="text-red-500 mt-2">{error}</p>}

      {/* Payment Button */}
      <div className="mt-4">
        {loading ? (
          <button className="bg-gray-600 text-white px-6 py-3 rounded-md" disabled>
            Loading...
          </button>
        ) : (
          <button
            onClick={handlePayment}
            className="bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700"
          >
            Pay {price} INR
          </button>
        )}
      </div>
    </Modal>
  );
};

export default PaymentModal;
