




import React, { useState } from 'react';
import apiClient from 'axiosInstance'; // Assuming apiClient is already set up

const DeleteAccount = () => {
    const [mobile, setMobile] = useState('');
    const [otp, setOtp] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [confirmation, setConfirmation] = useState(false);
    const [loading, setLoading] = useState(false); // New loading state

    // Function to check if the user exists
    const checkUserExistence = async (mobile) => {
        try {
            const response = await apiClient.post('/api/isuser/exist', { contactNumber: mobile });

            if (response.data.status) {
                return true; // User is registered
            } else {
                setErrorMessage('User not registered. Please register first.');
                return false; // User is not registered
            }
        }
        catch (err) {
            // Set the error message from backend response or fallback message
            if (err.response && err.response.data && err.response.data.message) {
              setErrorMessage(err.response.data.message);
              return false;
            } else {
              setError('Login failed. Please check your credentials.');
            }
            console.error('Login error:', err);
          }
    };

    // Function to handle OTP sending
    const handleSendOtp = async () => {
        if (!mobile) {
            setErrorMessage('Please enter a valid mobile number');
            return;
        }

        // Check if the user exists before sending OTP
        const userExists = await checkUserExistence(mobile);

        if (!userExists) {
            return; // Do not proceed if user doesn't exist
        }

        setLoading(true); // Start loading spinner

        try {
            const response = await apiClient.post('/api/send-otp', { contactNumber: mobile });
            if (response.data.success) {
                setOtpSent(true);
                setErrorMessage('');
            } else {
                setErrorMessage('Failed to send OTP');
            }
        } 
        catch (err) {
            // Set the error message from backend response or fallback message
            if (err.response && err.response.data && err.response.data.message) {
              setErrorMessage(err.response.data.message);
              return false;
            } else {
              setError('Login failed. Please check your credentials.');
            }
            console.error('Login error:', err);
          }
        
        finally {
            setLoading(false); // Stop loading spinner
        }
    };

    const handleDeleteAccount = async () => {
        if (!otp || !mobile) {
            setErrorMessage('Please provide both OTP and Mobile number');
            return;
        }

        if (!confirmation) {
            setErrorMessage('You must confirm before deleting');
            return;
        }

        setLoading(true); // Start loading spinner

        try {
            const response = await apiClient.post('/api/delete/user/with/otp', { mobile, otp });
            if (response.data.message === 'User account deleted successfully') {
                alert('Your account has been permanently deleted');
                window.location.href = '/'; // Redirect to home or login page
            } else {
                setErrorMessage(response.data.message);
            }
        } 
        catch (err) {
            // Set the error message from backend response or fallback message
            if (err.response && err.response.data && err.response.data.message) {
              setErrorMessage(err.response.data.message);
            } else {
              setError('Login failed. Please check your credentials.');
            }
            console.error('Login error:', err);
          }
         finally {
            setLoading(false); // Stop loading spinner
        }
    };

    return (
        <div className="max-w-md mx-auto mt-10 p-6 bg-white shadow-lg rounded-lg">
            <h2 className="text-xl font-semibold text-center">Delete Your Account</h2>

            {errorMessage && (
                <div className="mt-4 p-2 text-red-500 text-sm">{errorMessage}</div>
            )}

            <div className="mt-6">
                {!otpSent ? (
                    <>
                        <div>
                            <label htmlFor="mobile" className="block text-sm font-medium text-gray-700">Mobile Number</label>
                            <input
                                type="number"
                                id="mobile"
                                name="mobile"
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter your mobile number"
                            />
                        </div>

                        <button
                            onClick={handleSendOtp}
                            className="mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600"
                            disabled={loading} // Disable button when loading
                        >
                            {loading ? 'Sending OTP...' : 'Send OTP'} {/* Button text changes based on loading */}
                        </button>
                    </>
                ) : (
                    <>
                        <div>
                            <label htmlFor="otp" className="block text-sm font-medium text-gray-700">Enter OTP</label>
                            <input
                                type="text"
                                id="otp"
                                name="otp"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value)}
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                placeholder="Enter OTP"
                            />
                        </div>

                        <div className="mt-4">
                            <p className="text-sm text-gray-700">
                                Once you confirm, your account and data will be permanently deleted and cannot be recovered.
                            </p>

                            <div className="flex items-center mt-4">
                                <input
                                    type="checkbox"
                                    id="confirm"
                                    checked={confirmation}
                                    onChange={() => setConfirmation(!confirmation)}
                                    className="mr-2"
                                />
                                <label htmlFor="confirm" className="text-sm text-gray-700">I understand and want to permanently delete my account</label>
                            </div>

                            <button
                                onClick={handleDeleteAccount}
                                className="mt-4 w-full bg-red-500 text-white py-2 rounded-md hover:bg-red-600"
                                disabled={loading} // Disable button when loading
                            >
                                {loading ? 'Deleting Account...' : 'Delete Account'} {/* Button text changes based on loading */}
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DeleteAccount;
