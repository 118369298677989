import React from "react";

const ChildSafetyPage = () => {
  return (
    <div className="bg-gray-50 min-h-screen p-6">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-center text-gray-800">
          Child Safety Standards Compliance
        </h1>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Overview</h2>
          <p className="mt-2 text-lg text-gray-700">
            Google Play and Indian regulations require apps in Social and Dating
            categories to adhere to Child Safety Standards, ensuring the safety of
            children on the platform. Our app takes these standards seriously and
            works to keep the platform free of child sexual abuse and exploitation
            (CSAE).
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Published Standards</h2>
          <p className="mt-2 text-lg text-gray-700">
            Our app follows strict safety protocols to prevent child sexual abuse and exploitation (CSAE).
            We have published safety standards that include the following practices:
            <ul className="list-disc pl-6 mt-4 text-lg text-gray-700">
              <li>Strict moderation of user-generated content.</li>
              <li>Regular monitoring for potential CSAE content.</li>
              <li>Adherence to local and global child safety laws.</li>
            </ul>
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">User Reporting Mechanism</h2>
          <p className="mt-2 text-lg text-gray-700">
            We provide a simple and effective way for users to report concerns regarding child safety, including
            suspected child sexual abuse material (CSAM). The reporting feature can be accessed directly within the app.
            Users can flag inappropriate content, and we take immediate action in accordance with the law.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Contact for Child Safety</h2>
          <p className="mt-2 text-lg text-gray-700">
            If you have concerns about child safety or need to report CSAE, please reach out to us directly at:
            <a href="mailto:safety@dosso21.com" className="text-blue-600">support@dosso21.com</a>
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">Compliance with Child Safety Laws</h2>
          <p className="mt-2 text-lg text-gray-700">
            We comply with all relevant child safety laws and regulations in the regions where our app is used.
            We report any child sexual abuse material (CSAM) to the appropriate regional and national authorities.
          </p>
        </div>

        <div className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-800">For More Information</h2>
          <p className="mt-2 text-lg text-gray-700">
            For more information on our child safety practices, please visit our{" "}
            <a
              href="https://www.dosso21.com/safety-standards"
              className="text-blue-600"
              target="_blank"
              rel="noopener noreferrer"
            >
              safety standards page
            </a>.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ChildSafetyPage;
