

import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { IoClose } from 'react-icons/io5';
import axios from 'axios';
import apiClient from 'axiosInstance';
import PaymentModal from './PaymentModal';
import Select from 'react-select'; // Import react-select

const RegistrationModal = () => {

  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false); // State to handle modal open/close
  const [isOpen, setIsOpen] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    dob: '',
    email: '',
    address: '',
    type: '', // Default type "Cricketer"
    state: '',  // State for dropdown
    referalId: '', // Referral ID
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({
    name: '',
    mobile: '',
    dob: '',
    type: '',
    state: ''
  });
  
  const [states, setStates] = useState([]); // To store state options for the dropdown

  useEffect(() => {
    // Fetch states from backend when component mounts
    const fetchStates = async () => {
      try {
        const response = await axios.get('https://ratefry.com/api/electronics/states');  // Backend endpoint for states
        if (response.data) {
          const stateOptions = response.data.map(state => ({
            label: state.stateName,  // State name will be displayed
            value: state.stateName,  // The value sent to the backend
          }));
          setStates(stateOptions);
        }
      } catch (error) {
        console.error('Error fetching states:', error);
      }
    };
    fetchStates();
  }, []);

  const openPaymentModal = () => setIsPaymentModalOpen(true);  // Open the payment modal
  const closePaymentModal = () => setIsPaymentModalOpen(false); // Close the payment modal
  const closeModal = () => setIsOpen(false); // Close registration modal

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevState) => ({ ...prevState, [name]: value }));
  // };

  const handleSelectChange = (selectedOption, actionMeta) => {
    const { name } = actionMeta;  // name can be 'state' or 'type'
    setFormData((prevState) => ({ ...prevState, [name]: selectedOption.value }));
  };

  const handleRegisterUser = async () => {
    if (validateForm()) return;  // If the form is invalid, stop

    setLoading(true);
    try {
      const response = await apiClient.post('/api/create/user/cricket', formData);
      console.log(response);
      if(response.data.message === 'Mobile number already exists.'){
            alert(response.data.message);
            return
      }
      if(formData.type == 'Coach'  ||  formData.type == 'Umpire'){
        alert('Registerd Successfully')
      }
      if(formData.type !== 'Coach'  && formData.type != 'Umpire'){
        openPaymentModal();
      }
      setLoading(false);
      closeModal();
    } catch (err) {
      setLoading(false);
      alert(err.response?.data?.message || 'Registration failed');
      console.error('Registration error:', err);
    }
    setLoading(false);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.mobile) newErrors.mobile = 'Mobile is required';
    else if (!/^\d{10}$/.test(formData.mobile)) newErrors.mobile = 'Please enter a valid 10-digit mobile number.';
    if (!formData.dob) newErrors.dob = 'Date of Birth is required';
    if (!formData.type) newErrors.type = 'Type is required';
    if (!formData.state) newErrors.state = 'State is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length > 0;
  };




  const handleInputChange = (e) => {
        const { name, value } = e.target;
    
        // Set the value of the field in the form
        setFormData((prevState) => ({ ...prevState, [name]: value }));
    
        // Only perform the age check if the field is for DOB
        if (name === 'dob' && value) {
          const age = calculateAge(value);
          if (age < 16 || age > 23) {
            // Reset DOB and show the warning message
            setFormData((prevState) => ({ ...prevState, dob: '' }));
            setWarningMessage('Eligibility requires age between 16 and 23 years. Fee is non-refundable.');
          } else {
            // Clear warning message if age is valid
            setWarningMessage('');
          }
        }
    
        // Mobile number validation (10 digits)
        if (name === 'mobile' && value) {
          if (!/^\d{10}$/.test(value)) {
            setErrors((prevErrors) => ({ ...prevErrors, mobile: 'Please enter a valid 10-digit mobile number.' }));
          } else {
            setErrors((prevErrors) => ({ ...prevErrors, mobile: '' }));
          }
        }
      };
    
      // Function to calculate age based on date of birth
      const calculateAge = (dob) => {
        const birthDate = new Date(dob);
        const today = new Date();
        const age = today.getFullYear() - birthDate.getFullYear();
        const monthDiff = today.getMonth() - birthDate.getMonth();
    
        // Adjust age if the current date is before the birthday this year
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
          return age - 1;
        }
        return age;
      };
    

  return (
    <div>
      {/* Register Button */}
      <button
        className="bg-transparent text-white px-6 py-3 hover:text-blue-800 hover:scale-105 focus:outline-none mb-2 pb-2 transition-transform"
        onClick={() => setIsOpen(true)}
      >
        Register
      </button>

      {/* Modal for Registration Form */}
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Registration Form"
        className="max-w-md mx-auto my-20 pb-32 bg-white p-6 rounded-lg shadow-lg overflow-auto max-h-screen"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50"
      >
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-semibold">Register</h2>
          <button onClick={closeModal} className="text-gray-600 hover:text-gray-800 focus:outline-none">
            <IoClose size={24} />
          </button>
        </div>
        <div className="bg-red-200 text-red-800 p-4 rounded-md font-semibold text-sm">
          If your age is greater than 23 years old And less than 16 years, then you are not eligible. Fee will not be refunded.
        </div>

        {/* Form Inputs */}
        <div className="mb-4">
          <label className="block mb-2">Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
          {errors.name && <p className="text-red-600 text-sm">{errors.name}</p>}
        </div>

        <div className="mb-4">
          <label className="block mb-2">Mobile</label>
          <input
            type="number"
            name="mobile"
            value={formData.mobile}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
          {errors.mobile && <p className="text-red-600 text-sm">{errors.mobile}</p>}
        </div>

        <div className="mb-4">
          <label className="block mb-2">Date of Birth</label>
          <input
            type="date"
            name="dob"
            value={formData.dob}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
          {!formData.dob && <p className="text-red-600 text-sm">{errors.dob}</p>}
        </div>

        {warningMessage && (
          <div className="bg-red-200 text-red-800 p-4 rounded-md font-semibold text-sm">
            {warningMessage}
          </div>
        )}

        <div className="mb-4">
          <label className="block mb-2">Email</label>
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block mb-2">Address</label>
          <input
            type="text"
            name="address"
            value={formData.address}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
            required
          />
        </div>

        {/* Type Dropdown */}
        <div className="mb-4">
          <label className="block mb-2">Your Speciality</label>
          <Select
            name="type"
            value={{ label: formData.type, value: formData.type }}
            onChange={handleSelectChange}
            options={[
              { label: 'Coach', value: 'Coach' },
              { label: 'Bowler', value: 'Bowler' },
              { label: 'Batsman', value: 'Batsman' },
              { label: 'Wicketkeeper/Batsman', value: 'Wicketkeeper/Batsman' },
              { label: 'All Rounder', value: 'All Rounder' },
              { label: 'Umpire', value: 'Umpire' },
            ]}
             className="w-full max-w-full"
             isSearchable={false}
          />
             {errors.type && <p className="text-red-600 text-sm">{errors.type}</p>}
        </div>

        {/* State Dropdown */}
        <div className="mb-4">
          <label className="block mb-2">State</label>
          <Select
            name="state"
            value={formData.state ? { label: formData.state, value: formData.state } : null}
            onChange={handleSelectChange}
            options={states} // Populated from API
            className="w-full"
            isSearchable={false}
          />
           {errors.state && <p className="text-red-600 text-sm">{errors.state}</p>}
        </div>

  {/* Conditionally render referral ID */}
      {formData.type !== 'Coach'  && formData.type != 'Umpire' && (
        <div className="mb-4">
          <label className="block mb-2">Referral ID</label>
          <input
            type="text"
            name="referalId"
            value={formData.referalId}
            onChange={handleInputChange}
            className="w-full p-2 border border-gray-300 rounded-md"
          />
        </div>
         )}

        <button
          onClick={handleRegisterUser}
          className="bg-green-600 text-white px-6 py-3 rounded-md hover:bg-green-700 mb-5"
        >
          {loading ? 'Registering...' : 'Register'}
        </button>
      </Modal>

   
      <PaymentModal
        isOpen={isPaymentModalOpen}
        closeModal={closePaymentModal}
        initialMobile={formData.mobile}
      />
    
    </div>
  );
};

export default RegistrationModal;
