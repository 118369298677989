// src/components/PrivacyPolicy.js
import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-6 sm:py-12 lg:py-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-3xl font-semibold text-center text-gray-900 mb-6">Privacy Policy</h1>

        <p className="text-lg text-gray-700 mb-4">
          This Privacy Policy explains how we collect, use, and protect your personal information. By using our app,
          you agree to the collection and use of information in accordance with this policy.
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Information We Collect</h2>
          <p className="text-gray-700 mt-2">
            We only collect the following personal information from you:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-700">
            <li>Your Mobile Number</li>
            <li>Your Email Address</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">How We Use Your Information</h2>
          <p className="text-gray-700 mt-2">
            The information we collect is used for the following purposes:
          </p>
          <ul className="list-disc pl-6 mt-2 text-gray-700">
            <li>To provide personalized services.</li>
            <li>To improve our app functionality and user experience.</li>
            <li>To send important notifications, updates, and offers.</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">How We Protect Your Information</h2>
          <p className="text-gray-700 mt-2">
            We implement industry-standard security measures to protect your personal information. Your data is stored
            securely, and we do not share or sell it to third parties.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Your Rights</h2>
          <p className="text-gray-700 mt-2">
            You have the right to access, update, or delete the information we have collected about you. If you wish to
            do so, please contact us.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-gray-800">Changes to This Privacy Policy</h2>
          <p className="text-gray-700 mt-2">
            We may update our Privacy Policy from time to time. Any changes will be posted on this page, and we will notify
            users through the app if the changes are significant.
          </p>
        </section>

        <footer className="text-center text-gray-600 mt-8">
          <p>&copy; 2024 Dosso21.com. All rights reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
